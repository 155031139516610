import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

type HeadingBannerModuleProps = {
  urlImage: string;
  urlImageMobile: string;
  textTitle: any;
  textDescription: any;
  textButton: string;
  textLoadingButton: string;
  loading: boolean;
  onClick: () => void;
};
export default function HeadingBannerModule({
  urlImage,
  urlImageMobile,
  textTitle,
  textDescription,
  textButton,
  textLoadingButton,
  loading,
  onClick,
}: HeadingBannerModuleProps) {
  const [isMobile] = useMediaQuery('(max-width: 580px)');
  //linear-gradient(to right, #0187D1, #0279C7)
  //linear-gradient(to right, #0271C7, #0167C0)
  return (
    <Box color="white" bgGradient="linear(to-r,  #0182CD, #0A66CD)">
      <VStack>
        <VStack
          justify="center"
          w={'full'}
          h={'full'}
          bgGradient={
            isMobile
              ? 'linear-gradient(to right, #0271C7, #0167C0)'
              : 'linear-gradient(to right, #0187D1, #0279C7)'
          }
        >
          <CustomNextImage
            src={isMobile ? urlImageMobile : urlImage}
            alt={'Volta as aulas premiado'}
            width={isMobile ? '375px' : '1440px'}
            height={isMobile ? '320px' : '500px'}
          />
        </VStack>
        <Stack
          py={'3%'}
          px={'10px'}
          justifyContent={'space-around'}
          direction={isMobile?'column': 'row'}
          justify="center"
          w={'full'}
          h={'full'}
        >
          <VStack justify={"end"} >
            <Heading variant="lpBannerTitle">{textTitle}</Heading>
          </VStack>
          <VStack justifyContent={'space-between'} pt={['none', 1, 1, 2]}>
            <Text
              variant="lpDescriptionInfos"
              // opacity={isMobile ? '80%' : undefined}
            >
              {textDescription}
            </Text>
            <Button
              variant="lpBannerButton"
              onClick={onClick}
              isLoading={loading}
              loadingText={textLoadingButton}
              spinnerPlacement="start"
            >
              {textButton}
            </Button>
          </VStack>
        </Stack>
      </VStack>
    </Box>
  );
}
