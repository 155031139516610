import { Button, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

type BootomBannerModuleProps = {
  urlImage: string;
  urlImage2: string;
  urlImage3: string;
  textAccessButton: string;
  textTitle: string;
  loading: boolean;
  onClick: () => void;
};
export default function BootomBannerModule({
  urlImage,
  urlImage2,
  urlImage3,
  textAccessButton,
  textTitle,
  loading,
  onClick,
}: BootomBannerModuleProps) {
  // const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <VStack p={'5%'}>
      <Heading variant="lpBannerBootomTitle">{textTitle}</Heading>
      <Stack
        pt={'4'}
        justifyContent={'space-around'}
        direction={{
          base: 'column',
          sm: 'column', // 480px
          md: 'row', // 768px
          lg: 'row', // 992px
          xl: 'row', // 1280px
          '2xl': 'row', // 1536px
        }}
        align="stretch"
      >
        <VStack pt={'4'}>
          <CustomNextImage
            width={'200px'}
            height={'200px'}
            src={urlImage}
            alt={'Produtos TRIS'}
          />
          <Text variant="lpBannerBootomText">
            Compre R$30 ou mais em produtos TRIS
          </Text>
        </VStack>
        <VStack pt={'4'}>
          <CustomNextImage
            width={'200px'}
            height={'200px'}
            src={urlImage2}
            alt={'Nota fiscal'}
          />
          <Text variant="lpBannerBootomText">
            Cadastre sua nota <br /> fiscal aqui no site
          </Text>
          <Button
            variant="lpBannerBootomButton"
            onClick={onClick}
            isLoading={loading}
            loadingText={textAccessButton}
            spinnerPlacement="start"
          >
            Cadastrar notas
          </Button>
        </VStack>
        <VStack pt={'4'}>
          <CustomNextImage
            width={'200px'}
            height={'200px'}
            src={urlImage3}
            alt={'Roleta virtual'}
          />
          <Text variant="lpBannerBootomText">
            Gire a roleta virtual e descubra se você ganhou*!
          </Text>
        </VStack>
      </Stack>
    </VStack>
  );
}
