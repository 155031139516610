import {
    ModalContent,
    useDisclosure,
    ModalCloseButton,
    ModalBody,
    Modal,
    VStack,
    ModalOverlay,
    Text,
    Link,
  } from '@chakra-ui/react';
  import { useEffect } from 'react';
  
  export default function ModalModule() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    useEffect(() => {
      onOpen();
    }, []);
  
    return (
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <VStack py={5} spacing={5}>
              <Text fontSize={'xl'} fontWeight={"bold"}>PERÍODO DE PARTICIPAÇÃO ENCERRADO.</Text>
              <Text align={'center'} fontWeight={300}>
Agora é só aguardar a divulgação dos ganhadores aqui no hotsite:{" "}
                <Link href="https://promotris.com.br/" color={"blue"}>promotris.com.br</Link>!
              </Text>
              <Text fontWeight={300}>Obrigado pela sua participação!</Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }