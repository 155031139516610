import BootomBanner from './components/BootomBanner';
import HeadingBanner from './components/HeadingBanner';
import MiddleBanner from './components/MiddleBanner';
import { useSession } from 'next-auth/react';
import useTranslation from 'next-translate/useTranslation';
// import router from 'next/router';
// import BannerGames from './components/banner_games';
import BannerWinnerPrizes from './components/banner_winner_prizes';
import WinnerPrizesModal from '@components/WinnerPrizesModal';
import Trans from 'next-translate/Trans';
import { useStages } from '@hooks/useStages';
import logger from '@logger/logger';
import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
// import { GameDetailType, GameStatus, 
//   // GameStatus 
// } from '@interfaces/Game';
// import { getInteractionStatusReq } from '@services/interaction';
// import { isBefore } from 'date-fns';
import ModalModule from './components/Modal';
// import { isBefore } from 'date-fns';
// import { useMediaQuery } from '@chakra-ui/react';

export default function AnylovyModule() {
  const { t } = useTranslation('landing-page');
  const { status } = useSession();
  const toast = useToast();
  // const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [loading, setLoading] = useState(false);

  const { stages, loadNewStages } = useStages();

  function loadStages() {
    logger.info('loadStates called');
    loadNewStages().then(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    if (status !== 'loading' && !stages) {
      setLoading(true);
      loadStages();
    }
  }, [status, stages]);

  const handleGame = async () => {
    toast({
      title: 'Participação encerrada! ',
      description: `Período da promoção de 15/12/2023 até 15/02/2024.`,
      status: 'warning',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  };

  return (
    <>
      <ModalModule/>
      <HeadingBanner
        urlImageMobile={'/imgs/tris/banner_topo_campanha_VA_MOBILE-11-11.jpg'}
        urlImage={'/imgs/tris/banner_topo_novo_DESKTOP.jpg'}
        textTitle={
          <Trans
            i18nKey="landing-page:banner-header-title"
            components={[<span style={{ color: '#FBE654' }} />, <br />]}
          />
        }
        loading={loading}
        textDescription={
          <Trans
            i18nKey="landing-page:banner-header-description"
            components={[<br />]}
          />
        }
        textButton={t('banner-button')}
        onClick={handleGame}
        textLoadingButton={t('banner-button-loading')}
      />
      <MiddleBanner
        urlImage2={'/imgs/tris/prize-02.png'}
        urlImage={'/imgs/tris/prize-01.png'}
        textTitle={t('banner-prizes-title')}
      />
      <BootomBanner
        urlImage={'/imgs/tris/hotsite_campanha_VA_gif_sacola.gif'}
        urlImage2={'/imgs/tris/hotsite_campanha_VA_gif_nota.gif'}
        urlImage3={'/imgs/tris/hotsite_campanha_VA_gif_roleta.gif'}
        textTitle={t('banner-bootom-textTitle')}
        textAccessButton={t('banner-button-loading')}
        loading={loading}
        onClick={handleGame}
      />
      {/* <BannerGames /> */}
      <WinnerPrizesModal ChildComponet={BannerWinnerPrizes} />
    </>
  );
}
