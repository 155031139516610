import { Box, Heading, Stack, useMediaQuery } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

type MiddleBannerModuleProps = {
  urlImage2: string;
  urlImage: string;
  textTitle: string;
};
export default function MiddleBannerModule({
  urlImage,
  urlImage2,
  textTitle,
}: MiddleBannerModuleProps) {
  const [isMobile] = useMediaQuery('(max-width: 580px)');

  return (
    <Stack bg={'#0F37A0'} pt={'30px'} pb={'50px'}>
      <Box p={[0, 3]} px={'16'} textAlign={'center'}>
        <Heading variant="lpBannerTitle" color="#FFFFFF">
          {textTitle}
        </Heading>
      </Box>
      <Stack
        spacing={['24px', '90px']}
        justify={'center'}
        align={'center'}
        direction={['column', 'row']}
      >
        <Box
          width={isMobile ? '296px' : '400px'}
          height={isMobile ? '296px' : '400px'}
        >
          <CustomNextImage
            width={isMobile ? '296px' : '400px'}
            height={isMobile ? '296px' : '400px'}
            src={urlImage}
            alt={'1 super kit de produtos Tris'}
          />
        </Box>

        <Box
          width={isMobile ? '296px' : '400px'}
          height={isMobile ? '296px' : '400px'}
        >
          <CustomNextImage
            width={isMobile ? '296px' : '400px'}
            height={isMobile ? '296px' : '400px'}
            src={urlImage2}
            alt={'Premio de r$300'}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
